import { createContext, ReactNode, useContext } from 'react';
import {
  ExperimentVariantsFragment,
  useGetPartnerExperimentQuery,
} from '@codegen/cmsUtils';
import { Experiment } from '@web/types/experimentTypes';
import {
  getExperimentDataFromCMS,
  getExperimentKeys,
  getExperimentKeysFromQueryParams,
} from '@web/utils/experimentUtils';
import { useConstants } from './ConstantContext';

interface ExperimentData {
  experimentData?: ExperimentVariantsFragment;
  experiments?: Experiment[];
  isAlignedLuggageExperiment?: boolean;
  isNewCreateOrderExperiment?: boolean;
}

const context = createContext<ExperimentData>({});

const ExperimentProvider = ({ children }: { children: ReactNode }) => {
  const { locale, partner } = useConstants();

  const experimentKeysFromQueryParams = getExperimentKeysFromQueryParams();
  const experimentKeysFromCookies = getExperimentKeys();

  const experiments = [
    ...experimentKeysFromCookies,
    ...experimentKeysFromQueryParams,
  ];

  const shouldRunQuery = experiments.some(
    (experiment) => experiment.variant !== 'current',
  );

  const { data } = useGetPartnerExperimentQuery(
    {
      partner,
      locale,
    },
    {
      enabled: shouldRunQuery,
    },
  );

  const experimentData = getExperimentDataFromCMS({
    partnerExperiment: data?.partner,
    experiments,
  });

  const isAlignedLuggageExperiment = experiments.some(
    (exp) =>
      exp.name === 'experiment.2024q1-aligned-luggage' &&
      exp.variant === 'variantAlignedLuggage',
  );

  const isNewCreateOrderExperiment = experiments.some(
    (exp) =>
      exp.name === 'experiment.2024q2-new-create-order' &&
      exp.variant === 'variantNewCreateOrder',
  );

  return (
    <context.Provider
      value={{
        experiments,
        experimentData,
        isAlignedLuggageExperiment,
        isNewCreateOrderExperiment,
      }}
    >
      {children}
    </context.Provider>
  );
};

export default ExperimentProvider;

export const useExperiments = () => useContext(context);
