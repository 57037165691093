import { ParsedUrlQuery } from 'querystring';
import { useEffect, useRef, useState } from 'react';
import useCmsTranslation from '@hooks/useCmsTranslation';
import { DropdownValue } from '@shared/types/dropdownTypes';
import { Language, CurrencyCode } from '@shared/types/enums';
import Box from '@ui-v2/core/Box/Box';
import LinkButton from '@ui-v2/core/Button/LinkButton';
import Menu from '@ui-v2/core/Menu/Menu';
import Stack from '@ui-v2/core/Stack/Stack';
import useOnOutsideClick from '../../hooks/useOnOutsideClick';
import Dropdown from '../Dropdown/Dropdown';
import SettingsDisplay from './SettingsDisplay';

export interface Props {
  countries: DropdownValue[];
  currencies: DropdownValue[];
  currency: CurrencyCode;
  getHref: ({
    keepPreviousQuery,
    pathname,
    query,
  }: {
    keepPreviousQuery?: boolean | undefined;
    pathname?: string | undefined;
    query?: ParsedUrlQuery | undefined;
  }) => string;
  hideCurrencySelection?: Maybe<boolean>;
  hideResidencySelection?: Maybe<boolean>;
  languages: DropdownValue[];
  locale: Language;
  mainCurrencies: DropdownValue[];
  residency: string;
  route: string;
}

const Settings = ({
  countries,
  currencies,
  currency,
  getHref,
  hideCurrencySelection,
  hideResidencySelection,
  languages,
  locale,
  mainCurrencies,
  residency,
  route,
}: Props) => {
  const { t } = useCmsTranslation();
  const menuRef = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [settings, setSettings] = useState({
    currency,
    residency,
    language: locale,
  });

  useOnOutsideClick(menuRef, () => {
    if (isMenuOpen) {
      setIsMenuOpen(false);
    }
  });

  useEffect(
    () =>
      setSettings({
        currency,
        residency,
        language: locale,
      }),
    [currency, locale, residency],
  );

  if (
    hideResidencySelection &&
    hideCurrencySelection &&
    languages.length <= 1
  ) {
    return null;
  }

  const getUrl = () => {
    const [path, query] = route.split('?');

    const searchParams = new URLSearchParams(query);

    searchParams.set('residency', settings.residency);
    searchParams.set('currency', settings.currency);

    return getHref({
      pathname: `${path}?${searchParams}`,
      keepPreviousQuery: false,
    });
  };

  return (
    <Box alignItems="center" display="flex" position="relative">
      <SettingsDisplay
        currency={currency}
        isOpen={isMenuOpen}
        language={
          languages.find((lang) => lang.value === locale)?.label ||
          settings.language
        }
        toggleSettings={() => setIsMenuOpen(!isMenuOpen)}
      />

      <Menu
        align="right"
        id="settings-popup"
        isOpen={isMenuOpen}
        onOpenChange={(isOpen) => {
          setIsMenuOpen(isOpen);
        }}
        ref={menuRef}
        widthCustom={256}
      >
        <Box p={16}>
          <Stack gap={8} role="menu">
            {!hideResidencySelection && (
              <Dropdown
                id="Country"
                label={t('country', 'Country')}
                onChange={(val) => setSettings({ ...settings, residency: val })}
                role="menuitem"
                value={settings.residency}
                values={[{ values: countries }]}
              />
            )}
            {languages.length > 1 && (
              <Dropdown
                id="Language"
                label={t('language', 'Language')}
                onChange={(val) =>
                  setSettings({ ...settings, language: val as Language })
                }
                role="menuitem"
                value={settings.language}
                values={[{ values: languages }]}
              />
            )}
            {!hideCurrencySelection && (
              <Dropdown
                id="Currency"
                label={t('Currency', 'Currency')}
                onChange={(val) =>
                  setSettings({ ...settings, currency: val as CurrencyCode })
                }
                role="menuitem"
                value={settings.currency}
                values={[
                  {
                    label: t('Main currencies', 'Main currencies'),
                    values: mainCurrencies,
                  },
                  {
                    label: t('More currencies', 'More currencies'),
                    values: currencies,
                  },
                ]}
              />
            )}
          </Stack>
          <Box mt={16}>
            <LinkButton
              data-cy="settings-apply-button"
              href={getUrl()}
              locale={settings.language}
              onClick={() => setIsMenuOpen(false)}
              replace
              width="full"
            >
              {t('apply', 'Apply')}
            </LinkButton>
          </Box>
        </Box>
      </Menu>
    </Box>
  );
};

export default Settings;
