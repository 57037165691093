import useCmsTranslation from '@hooks/useCmsTranslation';
import StandaloneContentModal from '@ui/components/Content/StandaloneContentModal';
import { useConstants } from '@web/context/ConstantContext';
import useStandaloneContent from '@web/context/hooks/useStandaloneContent';
import { useSettings } from '@web/context/SettingsContext';

const CONTENT_IDENTIFYER = 'protect-group';

const ProtectGroupInformationModal = ({
  isOpen,
  toggleModal,
}: {
  isOpen: boolean;
  toggleModal: () => void;
}) => {
  const { t } = useCmsTranslation();
  const title = t('refundable_booking', 'Refundable Booking');
  const { locale } = useConstants();
  const { residency } = useSettings();

  const { content } = useStandaloneContent('protect-group');

  return (
    <StandaloneContentModal
      content={content}
      id={CONTENT_IDENTIFYER}
      isOpen={isOpen}
      locale={locale}
      residency={residency}
      title={title}
      toggleModal={toggleModal}
    />
  );
};

export default ProtectGroupInformationModal;
