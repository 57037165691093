import { ReactNode } from 'react';
import useCmsTranslation from '@hooks/useCmsTranslation';
import Box from '@ui-v2/core/Box/Box';
import Modal from '@ui-v2/core/Modal/Modal';
import OneButtonModalFooter from '@ui-v2/core/Modal/OneButtonModalFooter';
import Text from '@ui-v2/core/Text/Text';
import { useServiceConfig } from '@web/context/hooks/useServiceConfig/useServiceConfig';
import DohopConnectRichInformationModal from '../../Modals/DohopConnectRichInformationModal/DohopConnectRichInformationModal';
import useTieredContent, { TieredPackage } from '../hooks/useTieredContent';
import useTiers from '../hooks/useTiers';

const PremiumTier = () => {
  const { premiumTierName } = useServiceConfig();

  return (
    <Box
      bg="components.tieredConnection.interactive.default"
      border="subdued"
      borderRadius="badge"
      p={4}
    >
      <Text as="div" lineHeight="1" variant="heading-7">
        {premiumTierName}
      </Text>
    </Box>
  );
};

const BaseTier = () => {
  const { baseTierName } = useServiceConfig();

  return (
    <Box display="flex" flexDirection="row" gap={4}>
      <Box
        bg="surface.secondary"
        border="subdued"
        borderRadius="badge"
        height="auto"
        p={4}
      >
        <Text as="div" lineHeight="1" variant="heading-7">
          {baseTierName}
        </Text>
      </Box>
      <PremiumTier />
    </Box>
  );
};

export const includedMap: Record<TieredPackage['serviceTierId'], ReactNode> = {
  premium: <PremiumTier />,
  base: <BaseTier />,
  // Should not happen
  commission: <BaseTier />,
};

const TieredConnectionServiceModal = ({
  id,
  isOpen,
  onCloseModal,
}: {
  id: string;
  isOpen: boolean;
  onCloseModal: () => void;
}) => {
  const { hasMultipleTiers } = useTiers();
  const { t } = useCmsTranslation();
  const { allModalServices } = useTieredContent();

  if (!hasMultipleTiers) {
    return (
      <DohopConnectRichInformationModal
        isOpen={isOpen}
        onOpenChange={onCloseModal}
      />
    );
  }

  if (!allModalServices) {
    return null;
  }

  return (
    <Modal
      footer={
        <OneButtonModalFooter
          onOpenChange={onCloseModal}
          text={t('got_it', 'Got it')}
        />
      }
      header={
        <Box display="flex" flexDirection="column" gap={8}>
          <Text as="h3" variant="heading-3">
            {t('service_details', 'Service details')}
          </Text>
          <Text as="span" variant="body-2">
            {t(
              'service_details_description',
              'Below is more detailed information on what exactly is included in our services.',
            )}
          </Text>
        </Box>
      }
      id={id}
      isOpen={isOpen}
      maxWidth={750}
      mobileSize="full-screen"
      onOpenChange={onCloseModal}
    >
      <Box>
        {allModalServices.map((service) => (
          <Box
            borderBottom="subdued"
            display="flex"
            flexDirection="column"
            key={service.id}
            mb={16}
            pb={16}
          >
            <Box maxWidthCustom="70%" mb={4}>
              <Text as="h5" variant="heading-5">
                {service.title.value}
              </Text>
            </Box>
            <Box maxWidthCustom="80%" mb={12}>
              <Text as="p" colour="text.default" variant="body-2">
                {service.description.value}
              </Text>
            </Box>
            <Box alignItems="center" display="flex" flexDirection="row" gap={8}>
              <Text as="p" variant="heading-6">
                {t('included_in', 'Included in:')}
              </Text>
              {
                includedMap[
                  service.serviceTierId as TieredPackage['serviceTierId']
                ]
              }
            </Box>
          </Box>
        ))}
      </Box>
    </Modal>
  );
};

export default TieredConnectionServiceModal;
