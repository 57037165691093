import { ParsedUrlQuery } from 'querystring';
import dayjs, { Dayjs } from 'dayjs';
import {
  SearchWidgetConfigFragment,
  PassengerRulesFragment,
  PassengerRuleFragment,
} from '@codegen/cmsUtils';
import {
  OfferFragment,
  SearchResultQueryVariables,
  Offer,
  GetStationsQuery,
  OfferFilters,
  PickerCityFragment,
  PickerStationFragment,
  AvailabilityNodeFragment,
  PickerCountryFragment,
} from '@codegen/gatewayUtils';
import { TranslateCmsString } from '@hooks/useCmsTranslation';
import { CurrencyCode, Language, Partner, Sort } from '@shared/types/enums';
import { PassengerTypeProp } from '@ui/components/PassengerCounter/passengerTypes';
import { sortArray } from '@utils/arrayUtils';
import {
  isDateInFuture,
  isSameDay,
  parseDateString,
  getCorrectLocale,
} from '@utils/dateUtils';
import { createAnArray, wrapArrayIfNeeded } from '@utils/helperUtils';
import { parseGatewayItinerary } from '@utils/itineraryUtils';
import { parseQueryString } from '@utils/queryUtils';
import {
  SearchQueryParams,
  SearchQueryLocalStorageType,
  Item,
} from '@web/types/searchWidgetTypes';
import { parseBookingDeeplink } from '../booking/bookingUtils';
import { modifyTransferUrl } from '../offerUtils';

const constructPassengerTypeMinCount = ({
  passengerQueryParams,
  rule,
  rules,
}: {
  passengerQueryParams: { [paxType: string]: number[] };
  rule: PassengerRuleFragment;
  rules: PassengerRuleFragment[];
}) => {
  const guardianOfRule = rules.find(
    (aRule) => aRule.guardianPassengerType === rule.passengerType,
  );

  // If the current paxType has another paxType to be a guardian of,
  // we need to make sure we don't allow paxType to become larger than the minimum value of the current paxType
  if (guardianOfRule?.passengerType) {
    const guardianOfCurrentCount =
      passengerQueryParams[guardianOfRule.passengerType]?.length;

    return guardianOfCurrentCount !== undefined &&
      rule.minCount &&
      rule.minCount > guardianOfCurrentCount
      ? rule.minCount
      : guardianOfCurrentCount;
  }

  return rule.minCount;
};

const constructPassengerTypeMaxCount = ({
  isAtMaxCount,
  passengerQueryParams,
  rule,
}: {
  isAtMaxCount: boolean;
  passengerQueryParams: { [paxType: string]: number[] };
  rule: PassengerRuleFragment;
}) => {
  const currentCount =
    passengerQueryParams[
      rule.passengerType as keyof typeof passengerQueryParams
    ]?.length;

  if (rule.guardianPassengerType && rule.guardianMaxPerPassengerType) {
    const currentGuardianCount =
      passengerQueryParams[rule.guardianPassengerType]?.length;

    return isAtMaxCount
      ? currentCount
      : Number(currentGuardianCount) * rule.guardianMaxPerPassengerType;
  }

  return isAtMaxCount ? currentCount : rule.maxCount;
};

export const constructPassengerTypes = ({
  passengerQueryParams,
  passengerRules,
}: {
  passengerQueryParams: { [paxType: string]: number[] };
  passengerRules?: PassengerRulesFragment | null;
}): PassengerTypeProp[] => {
  const totalCount = Object.keys(passengerQueryParams).reduce<number>(
    (acc, key) => (passengerQueryParams[key]?.length ?? 0) + acc,
    0,
  );

  const isAtMaxCount = passengerRules?.maxCount
    ? passengerRules.maxCount <= totalCount
    : false;

  const sortedPassengerRules = sortArray({
    array: passengerRules?.rules ?? [],
    key: 'minAge',
    reverse: true,
  });

  const types = sortedPassengerRules.map((rule, index) => ({
    id: index.toString(),
    type: rule.passengerType || '',
    label: rule.label.value || '',
    minAge: rule.minAge,
    maxAge: rule.maxAge,
    minCount: constructPassengerTypeMinCount({
      rule,
      passengerQueryParams,
      rules: sortedPassengerRules,
    }),
    maxCount: constructPassengerTypeMaxCount({
      isAtMaxCount,
      rule,
      passengerQueryParams,
    }),
    count:
      passengerQueryParams[
        rule.passengerType as keyof typeof passengerQueryParams
      ]?.length ?? 0,
    ages: passengerQueryParams[
      rule.passengerType as keyof typeof passengerQueryParams
    ],
  }));

  return types;
};

export const constructNewPaxAges = ({
  currentPaxTypeAges = [],
  newAge,
  paxType,
  updateIndex,
}: {
  currentPaxTypeAges?: number[];
  newAge: number;
  paxType: string;
  updateIndex: number;
}) => ({
  [paxType]: currentPaxTypeAges.map((age, ageIndex) =>
    ageIndex === updateIndex ? newAge : age,
  ),
});

export const constructAddPaxQueryParams = ({
  currentPaxTypeAges = [],
  passengerRules,
  paxType,
}: {
  currentPaxTypeAges?: number[];
  passengerRules?: PassengerRulesFragment | null;
  paxType: string;
}) => {
  const defaultPaxValue =
    getPaxTypeMaxAge(paxType, passengerRules) ||
    getPaxTypeMinAge(paxType, passengerRules) ||
    NaN;

  return {
    [paxType]: [...currentPaxTypeAges, defaultPaxValue],
  };
};

export const constructRemovePaxQueryParams = ({
  currentPaxTypeAges = [],
  paxType,
}: {
  currentPaxTypeAges?: number[];
  paxType: string;
}) => ({
  [paxType]: currentPaxTypeAges.slice(0, -1),
});

export const getPaxTypeMinAge = (
  paxType: string,
  passengerRules?: PassengerRulesFragment | null,
) =>
  passengerRules?.rules.find((rule) => rule.passengerType === paxType)
    ?.minAge || 0;

export const getPaxTypeMaxAge = (
  paxType: string,
  passengerRules?: PassengerRulesFragment | null,
) =>
  passengerRules?.rules.find((rule) => rule.passengerType === paxType)?.maxAge;

export const getPaxTypeMinCount = (
  paxType: string,
  passengerRules?: PassengerRulesFragment | null,
) =>
  passengerRules?.rules.find((rule) => rule.passengerType === paxType)
    ?.minCount || 0;

export const getSearchQueryParamsFromLocalStorage = (
  passengerRules: Maybe<PassengerRulesFragment>,
) => {
  if (typeof Storage === 'undefined' || !passengerRules) {
    return null;
  }

  const lsOrigins = localStorage.getItem(SearchQueryParams.ORIGINS);
  const lsDestinations = localStorage.getItem(SearchQueryParams.DESTINATIONS);
  const lsCurrency = localStorage.getItem(SearchQueryParams.CURRENCY);
  const lsIsOneWay = localStorage.getItem(SearchQueryParams.IS_ONEWAY);

  return {
    origins: lsOrigins,
    destinations: lsDestinations,
    currency: lsCurrency,
    isOneWay: lsIsOneWay,
  };
};

export const findItemByCode = (items: Item[], code: string): Item | undefined =>
  items.reduce<Item | undefined>((item, iterItem) => {
    if (item) {
      return item;
    }

    return code === iterItem.code
      ? iterItem
      : findItemByCode(iterItem.subItems, code);
  }, undefined);

export const constructStationQueryParameters = ({
  code,
  originalItems,
}: {
  code: string;
  originalItems: Item[];
}) => {
  const originalItem = findItemByCode(originalItems, code);

  if (originalItem?.subItems && originalItem.subItems.length > 0) {
    return originalItem.subItems.map((subItem) => subItem.code);
  }

  return [code];
};

export const removeSearchQueryParamFromLocalStorage = (key: string) => {
  if (typeof Storage === 'undefined' || !key) {
    return null;
  }

  localStorage.removeItem(key);
};

export const constructSearchQueryParams = (
  queryParams: ParsedUrlQuery,
  passengerRules?: PassengerRulesFragment | null,
  defaultParams?: Maybe<SearchQueryLocalStorageType>,
) => {
  const {
    currency = defaultParams?.currency,
    departureDate = defaultParams?.departureDate,
    destinations = defaultParams?.destinations?.split(','),
    isOneWay = defaultParams?.isOneWay,
    origins = defaultParams?.origins?.split(','),
    residency = defaultParams?.residency,
    returnDate = defaultParams?.returnDate,
    ...passengerQueryParams
  } = queryParams;

  const isOneWayParsed = isOneWay === 'true';

  const bookingParams = parseBookingDeeplink(queryParams, passengerRules);

  if (
    bookingParams?.departureDate &&
    bookingParams.origin &&
    bookingParams.destination &&
    passengerRules
  ) {
    return {
      origins: [bookingParams.origin],
      destinations: [bookingParams.destination],
      residency: residency as string | undefined,
      currency: currency as CurrencyCode,
      utmSource: bookingParams.utmSource as string | undefined,
      utmMedium: bookingParams.utmMedium as string | undefined,
      utmCampaign: bookingParams.utmCampaign as string | undefined,
      isOneWay: Boolean(bookingParams.isOneWay),
      departureDate: dayjs(bookingParams.departureDate),
      returnDate: bookingParams.returnDate
        ? dayjs(bookingParams.returnDate)
        : null,
      paxTypeAges: bookingParams.paxTypeAges,
    };
  }

  return {
    origins:
      typeof origins === 'object' ? origins.filter((origin) => origin) : [],
    // eslint-disable-next-line no-nested-ternary
    destinations: destinations?.includes('*')
      ? []
      : typeof destinations === 'object'
        ? destinations.filter((destination) => destination)
        : [],
    departureDate:
      departureDate && departureDate !== 'null'
        ? parseDateString(parseQueryString(departureDate))
        : null,
    returnDate:
      returnDate && returnDate !== 'null'
        ? parseDateString(parseQueryString(returnDate))
        : null,
    isOneWay: isOneWayParsed,
    currency,
    residency,
    carrierCodes: queryParams.carrierCodes
      ? wrapArrayIfNeeded(parseQueryString(queryParams.carrierCodes))
      : undefined,
    paxTypeAges: passengerRules
      ? passengerRules.rules.reduce<{
          [paxType: string]: number[];
        }>(
          (acc, rule) =>
            rule.passengerType
              ? {
                  ...acc,
                  [rule.passengerType]:
                    (passengerQueryParams[rule.passengerType] !== '' &&
                      typeof passengerQueryParams[rule.passengerType] ===
                        'string' &&
                      (
                        passengerQueryParams[rule.passengerType] as
                          | string
                          | undefined
                      )
                        ?.split(',')
                        .map((paxAge) => Number(paxAge))) ||
                    (defaultParams &&
                    rule.passengerType in defaultParams &&
                    defaultParams[
                      rule.passengerType as keyof typeof defaultParams
                    ] !== ''
                      ? defaultParams[
                          rule.passengerType as keyof typeof defaultParams
                        ]
                          ?.split(',')
                          .map((paxAge: string) => Number(paxAge))
                      : null) ||
                    createAnArray(
                      getPaxTypeMinCount(rule.passengerType, passengerRules),
                      getPaxTypeMaxAge(rule.passengerType, passengerRules) ||
                        getPaxTypeMinAge(rule.passengerType, passengerRules),
                    ),
                }
              : acc,
          {},
        )
      : {},
  };
};

export const constructOfferQueryVariables = ({
  activeFilters,
  activeSort,
  carrierCodes,
  currency,
  departureDate,
  destinations,
  isOneWay,
  language,
  limit,
  origins,
  partner,
  paxTypeAges,
  residency,
  returnDate,
  utmSource,
}: {
  activeFilters?: OfferFilters | null;
  activeSort?: Sort | null;
  carrierCodes?: string[];
  currency?: CurrencyCode;
  departureDate: Dayjs | null;
  destinations: string[];
  isOneWay: boolean;
  language: Language;
  limit: number;
  origins: string[];
  partner: Partner;
  paxTypeAges: { [paxType: string]: number[] };
  residency?: string;
  returnDate: Dayjs | null;
  utmSource?: string;
}): SearchResultQueryVariables => ({
  partner,
  metadata: {
    language,
    currency: currency || undefined,
    country: residency || undefined,
  },
  origin: origins.join(','),
  destination: destinations.join(','),
  departureDateString: dayjs(departureDate).format('YYYY-MM-DD'),
  returnDateString: isOneWay ? null : dayjs(returnDate).format('YYYY-MM-DD'),
  passengerAges: Object.values(paxTypeAges).reduce<number[]>(
    (acc, ages) => [...acc, ...ages],
    [],
  ),
  filters: {
    ...activeFilters,
    carrierCodes: activeFilters?.carrierCodes || carrierCodes || [],
  },
  sort: activeSort,
  limit,
  utmSource,
});

export const constructOffer = ({
  newBookingFlowEnvironments,
  offer,
  utmCampaign,
  utmMedium,
  utmSource,
}: {
  newBookingFlowEnvironments?: string[];
  offer: OfferFragment;
  utmCampaign?: string;
  utmMedium?: string;
  utmSource?: string;
}) => {
  const transferURL = modifyTransferUrl({
    url: offer.transferURL,
    newBookingFlowEnvironments,
    utmSource,
    utmMedium,
    utmCampaign,
  });

  return {
    ...offer,
    itinerary: parseGatewayItinerary(offer.itinerary),
    transferURL,
  } as Offer;
};

export const constructOffers = ({
  newBookingFlowEnvironments,
  offers,
  utmCampaign,
  utmMedium,
  utmSource,
}: {
  newBookingFlowEnvironments?: string[];
  offers?: OfferFragment[] | null;
  utmCampaign?: string;
  utmMedium?: string;
  utmSource?: string;
}): OfferFragment[] => {
  return (offers ?? []).map((offer) =>
    constructOffer({
      offer,
      utmSource,
      utmMedium,
      utmCampaign,
      newBookingFlowEnvironments,
    }),
  );
};

export const constructSubItemValue = (
  item: PickerCityFragment | PickerStationFragment,
  t: TranslateCmsString,
) => {
  const hasStations = 'stations' in item && item.stations.length > 0;
  const code = hasStations ? `(${t('Any', 'Any')})` : `(${item.code})`;
  const isTrain = item.transportType === 'TRAIN';

  return `${item.name}${!isTrain ? ` ${code}` : ''}`;
};

export const getRankCities = (item: PickerCityFragment) => {
  const ranks = item.stations.map((station) => station.rank);
  const maxRank = Math.max(...ranks);

  return maxRank !== -Infinity ? maxRank : 0;
};

export const getRankCountries = (item: Maybe<PickerCountryFragment>) => {
  if (!item) {
    return 0;
  }
  const ranks = item.cities.map((city) => getRankCities(city));
  const maxRank = Math.max(...ranks);

  return maxRank !== -Infinity ? maxRank : 0;
};

// Calls the stations endpoint and returns items that can be used inside our search autocomplete
export const constructItems = ({
  data,
  t,
}: {
  data: GetStationsQuery;
  t: TranslateCmsString;
}): Item[] =>
  data.stations.map((station) => ({
    value: station?.name,
    code: station?.code,
    rank: getRankCountries(station),
    subItems: station?.cities.map((city) => ({
      value: constructSubItemValue(city, t),
      code: city.code,
      rank: getRankCities(city),
      isSelectable: true,
      transportType: city.transportType,
      subItems: city.stations.map((cityStation) => ({
        value: constructSubItemValue(cityStation, t),
        code: cityStation.code,
        rank: cityStation.rank,
        transportType: cityStation.transportType,
        isSelectable: true,
        subItems: [],
      })),
    })),
    isSelectable: false,
  })) as Item[];

export const constructSearchWidgetConfig = (
  searchWidgetConfig?: Maybe<SearchWidgetConfigFragment>,
) => {
  if (searchWidgetConfig) {
    return {
      variant: searchWidgetConfig.variant,
    };
  }

  return {
    variant: null,
  };
};

export const getClosestAvailableDate = (
  selectedDate: Dayjs,
  availableDays?: Maybe<AvailabilityNodeFragment>[],
) => {
  return availableDays?.find((day) => {
    return (
      day &&
      isDateInFuture(day.date) &&
      dayjs(day.date).diff(selectedDate, 'day') > 0
    );
  })?.date;
};

export const getClosestAvailableRountrip = ({
  availableDepatureDays,
  checkIfArrivalDateIsDisabled,
  departureDate,
  returnDate,
}: {
  availableDepatureDays?: Maybe<AvailabilityNodeFragment>[];
  checkIfArrivalDateIsDisabled: (date: Dayjs, departureDate?: Dayjs) => boolean;
  departureDate: Dayjs;
  returnDate: Dayjs;
}) => {
  const numberOfDays = returnDate.diff(departureDate, 'day');

  // Loop through the available departure days and find the first available return date that is 'numberOfDays' away from the departure date
  const closestAvailableDepartureDate = availableDepatureDays?.find(
    (availableDepartureDate) => {
      const possibleNewArrivalDate = dayjs(availableDepartureDate?.date).add(
        numberOfDays,
      );

      return (
        checkIfArrivalDateIsDisabled(possibleNewArrivalDate) &&
        !dayjs(availableDepartureDate?.date).isSame(departureDate) &&
        dayjs(availableDepartureDate?.date).isAfter(departureDate)
      );
    },
  );

  availableDepatureDays?.find(
    (availableDepartureDate) =>
      !checkIfArrivalDateIsDisabled(
        dayjs(availableDepartureDate?.date).add(numberOfDays),
      ) &&
      // And is not the same date as the user has picked
      !dayjs(availableDepartureDate?.date).isSame(departureDate),
  );

  if (!closestAvailableDepartureDate) {
    return [];
  }

  const secondClosestAvailableDepartureDate = availableDepatureDays?.find(
    (availableDepartureDate) =>
      !checkIfArrivalDateIsDisabled(
        dayjs(availableDepartureDate?.date).add(numberOfDays),
      ) &&
      dayjs(availableDepartureDate?.date).isAfter(
        closestAvailableDepartureDate.date,
      ) &&
      // And is not the same date as the user has picked
      !dayjs(availableDepartureDate?.date).isSame(departureDate),
  );

  return [
    {
      departureDate: closestAvailableDepartureDate.date,
      returnDate: dayjs(closestAvailableDepartureDate.date)
        .add(numberOfDays, 'day')
        .toString(),
    },
    ...(secondClosestAvailableDepartureDate
      ? [
          {
            departureDate: secondClosestAvailableDepartureDate.date,
            returnDate: dayjs(secondClosestAvailableDepartureDate.date)
              .add(numberOfDays, 'day')
              .toString(),
          },
        ]
      : []),
  ];
};

export const parseDaysResponse = (days: AvailabilityNodeFragment[]) => {
  return days.map((d) => {
    return { ...d, date: parseDateString(d.date) };
  });
};

export const findDate = (
  date: Dayjs,
  days: Maybe<AvailabilityNodeFragment>[],
) => {
  return (
    days.find((day) =>
      day?.date ? isSameDay(parseDateString(day.date), date) : null,
    ) ?? null
  );
};

export const findDateByType = ({
  arrivalDays,
  date,
  departureDate,
  departureDays,
  isOneWay,
  returnDate,
}: {
  arrivalDays: Maybe<AvailabilityNodeFragment>[];
  date: Dayjs;
  departureDate: Maybe<Dayjs>;
  departureDays: Maybe<AvailabilityNodeFragment>[];
  isOneWay?: boolean;
  returnDate: Maybe<Dayjs>;
}) => {
  if (!departureDate || isOneWay) {
    return findDate(date, departureDays);
  }

  if (!returnDate) {
    return findDate(date, arrivalDays);
  }

  // If the departureDate and returnDate have been set and this date is the same as the returnDate
  // We show the returnDate price since that date is selected as the returnDate
  if (isSameDay(date, returnDate)) {
    return findDate(date, arrivalDays);
  }

  return findDate(date, departureDays);
};

export const findItemParentByCode = (
  items: Item[],
  codes: string[],
  parentItem?: Item,
): Item | undefined =>
  items.reduce<Item | undefined>((item, iterItem) => {
    if (item) {
      return item;
    }

    if (codes.includes(iterItem.code) && codes.length === 1) {
      return iterItem;
    }

    return codes.includes(iterItem.code) &&
      !iterItem.subItems.some((it) => codes.includes(it.code))
      ? parentItem
      : findItemParentByCode(iterItem.subItems, codes, iterItem);
  }, undefined);

export const renderDatePickerInputString = ({
  date,
  locale,
  residency,
}: {
  date: Dayjs | null | undefined;
  locale: Language | string;
  residency: string;
}) => {
  const correctLocale = getCorrectLocale(locale, residency);

  return date
    ? // Exception: We don't want localized date times here

      // eslint-disable-next-line no-restricted-syntax
      date.toDate().toLocaleString(correctLocale, {
        day: 'numeric',
        month: 'short',
        hourCycle: 'h23',
      })
    : '';
};
